import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './assets/css/Styles.css';

/* LOGOS */
import Logo from './assets/images/logo.svg';
import LogoBlack from './assets/images/logoBlack.svg';

/* IMAGES */
import About from './assets/images/about.jpg';
import Accompagnement from './assets/images/accompagnement.jpg';
import Ceremonie from './assets/images/ceremonie.jpg';
import Consultation from './assets/images/consultation.jpg';
import Expertise from './assets/images/expertise.jpg';
import Mission from './assets/images/mission.jpg';
import Objectif from './assets/images/objectif.jpg';
import Unicite from './assets/images/unicite.jpg';

/* VIDEOS */
import Video from './assets/videos/header-min.mov'; 
import VideoIOS from './assets/videos/header-min.webm'; 

const App = () => {
  const [language, setLanguage] = useState('fr');
  const [menuOpen, setMenuOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [unicityOpen, setUnicityOpen] = useState(false);
  const [consultationOpen, setConsultationOpen] = useState(false);
  const [ceremonieOpen, setCeremonieOpen] = useState(false);
  const [accompagnementOpen, setAccompagnementOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const switchLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleTerms = () => {
    setTermsOpen(!termsOpen);
  };

  const toggleUnicity = () => {
    setUnicityOpen(!unicityOpen);
  };

  const toggleConsultation = () => {
    setConsultationOpen(!consultationOpen);
  };

  const toggleCeremonie = () => {
    setCeremonieOpen(!ceremonieOpen);
  };

  const toggleAccompagnement = () => {
    setAccompagnementOpen(!accompagnementOpen);
  };

  const handleSmoothScroll = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const headerOffset = document.querySelector('.header').offsetHeight;
      const elementPosition = targetElement.offsetTop;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    toggleMenu();
};

  const handleReadMore = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  const Email = ({ email, children }) => {
    return <a href={`mailto:${email}`} className='link-button'>{children}</a>;
  };

  let currentYear = new Date().getFullYear();

  return (
    <div className="App" id='top'>
      <header className={`header ${isScrolled ? 'scrolled' : 'unscrolled'}`}> {/* Add scrolled class when the menu is open or when scrolling */}
        <div className="logo"><img src={isScrolled ? LogoBlack : Logo} alt="Logo" style={{ height: isScrolled ? '50px' : '80px', transition: 'height 0.3s' }} /></div>
        <button className={`hamburger ${isScrolled ? 'black' : 'white'}`} onClick={toggleMenu}>
          ☰
        </button>
      </header>

      <CSSTransition in={menuOpen} timeout={300} classNames="menu-fade" unmountOnExit>
        <div className="menu-overlay" style={{ zIndex: 20 }} onClick={toggleMenu}>
          <div className="menu" style={{ zIndex: 30 }} onClick={(e) => e.stopPropagation()}>
            <button className="close-menu" onClick={toggleMenu}>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6667 30.5132L9.48669 29.3332L18.82 19.9999L9.48669 10.6666L10.6667 9.48657L20 18.8199L29.3334 9.48657L30.5134 10.6666L21.18 19.9999L30.5134 29.3332L29.3334 30.5132L20 21.1799L10.6667 30.5132Z" fill="black"/>
              </svg>
            </button>
            <nav>
              <ul>
                <li>
                  <a href="#about" onClick={(e) => handleSmoothScroll(e, 'about')}>
                    {language === 'fr' ? 'À propos' : 'обо мне'}
                  </a>
                </li>
                <li>
                  <a href="#mission" onClick={(e) => handleSmoothScroll(e, 'mission')}>
                    {language === 'fr' ? 'Mission' : 'миссия'}
                  </a>
                </li>
                <li>
                  <a href="#expertise" onClick={(e) => handleSmoothScroll(e, 'expertise')}>
                    {language === 'fr' ? 'Comment puis-je vous être utile ?' : 'чем я могу быть полезна?'}
                  </a>
                </li>
                <li>
                  <a href="#services" onClick={(e) => handleSmoothScroll(e, 'services')}>
                    {language === 'fr' ? 'Services & tarifs' : 'услуги и стоимость'}
                  </a>
                </li>
                <li>
                  <a href="#contact" onClick={(e) => handleSmoothScroll(e, 'contact')}>
                    {language === 'fr' ? 'Contact' : 'контакты'}
                  </a>
                </li>
              </ul>
              <div className="menu-social">
                <a href="http://t.me/tribute/app?startapp=se7q" target="_blank" rel="noopener noreferrer">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1_116)">
                      <path d="M20.0001 0C8.9601 0 0.00012207 8.95998 0.00012207 20C0.00012207 31.0399 8.9601 39.9999 20.0001 39.9999C31.0401 39.9999 40 31.0399 40 20C40 8.95998 31.0401 0 20.0001 0ZM29.2801 13.6C28.9801 16.76 27.6801 24.44 27.0201 27.9799C26.7401 29.4799 26.1801 29.9799 25.6601 30.0399C24.5001 30.1399 23.6201 29.2799 22.5001 28.5399C20.7401 27.3799 19.7401 26.6599 18.0401 25.5399C16.0601 24.24 17.3401 23.52 18.4801 22.36C18.7801 22.06 23.9001 17.4 24.0001 16.98C24.014 16.9164 24.0121 16.8503 23.9947 16.7876C23.9773 16.7248 23.9448 16.6673 23.9001 16.62C23.7801 16.52 23.6201 16.56 23.4801 16.58C23.3001 16.62 20.5001 18.48 15.0401 22.16C14.2401 22.7 13.5201 22.98 12.8801 22.96C12.1601 22.94 10.8001 22.56 9.7801 22.22C8.5201 21.82 7.54011 21.6 7.62011 20.9C7.66011 20.54 8.16011 20.18 9.1001 19.8C14.9401 17.26 18.8201 15.58 20.7601 14.78C26.3201 12.46 27.4601 12.06 28.2201 12.06C28.3801 12.06 28.7601 12.1 29.0001 12.3C29.2001 12.46 29.2601 12.68 29.2801 12.84C29.2601 12.96 29.3001 13.32 29.2801 13.6Z"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1_116">
                        <rect width="40" height="40" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a href="https://www.instagram.com/smi_yulik?igsh=MXQwOHZvZTk2Y3ppZQ==" target="_blank" rel="noopener noreferrer">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1_114)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 17.7778C0 9.39778 -2.38419e-07 5.20667 2.60444 2.60444C5.20889 0.00222206 9.39778 0 17.7778 0H22.2222C30.6022 0 34.7933 -2.38419e-07 37.3956 2.60444C39.9978 5.20889 40 9.39778 40 17.7778V22.2222C40 30.6022 40 34.7933 37.3956 37.3956C34.7911 39.9978 30.6022 40 22.2222 40H17.7778C9.39778 40 5.20667 40 2.60444 37.3956C0.00222206 34.7911 0 30.6022 0 22.2222V17.7778ZM33.3333 10C33.3333 10.8841 32.9821 11.7319 32.357 12.357C31.7319 12.9821 30.8841 13.3333 30 13.3333C29.1159 13.3333 28.2681 12.9821 27.643 12.357C27.0179 11.7319 26.6667 10.8841 26.6667 10C26.6667 9.11594 27.0179 8.2681 27.643 7.64298C28.2681 7.01786 29.1159 6.66667 30 6.66667C30.8841 6.66667 31.7319 7.01786 32.357 7.64298C32.9821 8.2681 33.3333 9.11594 33.3333 10ZM24.4444 22.2222C24.4444 23.401 23.9762 24.5314 23.1427 25.3649C22.3092 26.1984 21.1787 26.6667 20 26.6667C18.8213 26.6667 17.6908 26.1984 16.8573 25.3649C16.0238 24.5314 15.5556 23.401 15.5556 22.2222C15.5556 21.0435 16.0238 19.913 16.8573 19.0795C17.6908 18.246 18.8213 17.7778 20 17.7778C21.1787 17.7778 22.3092 18.246 23.1427 19.0795C23.9762 19.913 24.4444 21.0435 24.4444 22.2222ZM28.8889 22.2222C28.8889 24.5797 27.9524 26.8406 26.2854 28.5076C24.6184 30.1746 22.3575 31.1111 20 31.1111C17.6425 31.1111 15.3816 30.1746 13.7146 28.5076C12.0476 26.8406 11.1111 24.5797 11.1111 22.2222C11.1111 19.8647 12.0476 17.6038 13.7146 15.9368C15.3816 14.2698 17.6425 13.3333 20 13.3333C22.3575 13.3333 24.6184 14.2698 26.2854 15.9368C27.9524 17.6038 28.8889 19.8647 28.8889 22.2222Z"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1_114">
                        <rect width="40" height="40" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a href="https://www.facebook.com/juliya.onyschenko" target="_blank" rel="noopener noreferrer">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1_112)">
                      <path d="M20 6.10352e-05C8.99999 6.10352e-05 0 9.01612 0 20.1205C0 30.1607 7.31999 38.494 16.88 40V25.9438H11.8V20.1205H16.88V15.6828C16.88 10.6426 19.86 7.87154 24.44 7.87154C26.62 7.87154 28.9 8.25306 28.9 8.25306V13.2129H26.38C23.9 13.2129 23.12 14.7591 23.12 16.3454V20.1205H28.68L27.78 25.9438H23.12V40C27.8329 39.2527 32.1244 36.8384 35.2198 33.1929C38.3153 29.5474 40.0106 24.911 39.9999 20.1205C39.9999 9.01612 31 6.10352e-05 20 6.10352e-05Z"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1_112">
                        <rect width="40" height="40" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
              <div className="menu-languages">
                <button onClick={() => switchLanguage('fr')} className="lang-button">🇫🇷</button>
                <button onClick={() => switchLanguage('ru')} className="lang-button">🇷🇺</button>
              </div>
            </nav>
          </div>
        </div>
      </CSSTransition>

      <main>
        <section id="hero" className="hero">
          <video autoPlay muted loop playsInline className="background-video">
            <source src={Video} type="video/mp4" />
            <source src={VideoIOS} type="video/webm" />
            Your browser does not support the video tag.
          </video>

          <div className="hero-content">
            <h1>{language === 'fr' ? 'Ici et Maintenant' : 'ЗДЕСЬ И СЕЙЧАС'}</h1>
            <hr />
            <p>
              {language === 'fr'
                ? 'Bienvenue dans un espace de transformation profonde. Ici commence votre voyage vers la complétude.'
                : 'Добро пожаловать в пространство глубоких трансформаций. Здесь начинается ваше путешествие к целостности.'}
            </p>
            <button className="contact-button" onClick={(e) => handleReadMore(e, 'contact')}>{language === 'fr' ? 'Contact' : 'контакты'}</button>
          </div>
        </section>

        <content>
          <section id="about" className="about">
            
            <div className="grid-container">
              <h2 className='grid-item-span-12'>{language === 'fr' ? 'À propos' : 'обо мне'}</h2>
              <div className="grid-item grid-item-span-4">
                <img src={About} alt="A propos" />
              </div>
              <div className="grid-item grid-item-span-8">
              <p><strong><em>
                {language === 'fr'
                  ? "Je suis une spécialiste qui vous aide à créer une synergie entre l'esprit, le corps et l'âme, en alliant les méthodes scientifiques de la psychologie à la profondeur des pratiques spirituelles. Ma mission est de vous accompagner sur le chemin de l'introspection et de la transformation profonde, de vous aider à surmonter les conflits intérieurs, à découvrir et à révéler vos ressources cachées."
                  : "Я специалист, который помогает вам создать синергию между умом, телом и душой, соединяя научные методы психологии с глубиной духовных практик. Моя задача заключается в том, чтобы поддержать вас на пути к самопознанию и глубокой трансформации, помочь преодолеть внутренние конфликты, обнаружить и проявить скрытые ресурсы."}
              </em></strong></p>
              <p>
                {language === 'fr'
                  ? "Actuellement, je suis en master à la faculté de psychologie et je prépare également une qualification de psychologue clinicien. Cette année est la dernière de ma formation, et mes connaissances sont la garantie que, quelle que soit la prestation que vous choisissez, je créerai pour vous un espace sûr et bienveillant."
                  : "На данный момент я учусь в магистратуре на факультете психологии и параллельно получаю квалификацию клинического психолога. Этот год — завершающий в моем обучении, и мои знания — это гарантия того, что независимо от выбранной вами услуги, я создам для вас безопасное и поддерживающее пространство."}
              </p>
              <p>
                {language === 'fr'
                  ? "Mon expérience inclut également une formation auprès des principaux experts dans le domaine des pratiques spirituelles et du travail sur le subconscient. J'ai été formée aux techniques de respiration énergétique par Roman Karlovsky, au theta healing par Vianna Stibal, ainsi qu'au travail sur le subconscient et la puissance de la pensée par John Kehoe. De plus, j'ai suivi une formation sur la cérémonie sacrée de l'enveloppement, qui comprenait également l'apprentissage de la technique du massage Rebozo."
                  : "Мой опыт также включает обучение у ведущих экспертов в области духовных практик и работы с подсознанием. Я обучалась техникам энергодыхания у Романа Карловского, тета-хиллингу у Вианы Стайбл, а также работе с подсознанием и силой разума у Джона Кехо. Кроме того, я прошла обучение сакральной церемонии пеленания, которое также включало в себя изучение техники ребозо массажа."}
              </p>
              <p>
                {language === 'fr'
                  ? "Je crois que la véritable transformation commence de l'intérieur. Mon travail repose sur une compréhension profonde de la psyché humaine et sur l'utilisation des pratiques spirituelles, qui vous permettent d'atteindre l'harmonie et l'intégrité intérieure. Ensemble, nous créerons un espace où la science et les anciennes connaissances spirituelles fonctionnent en harmonie, vous aidant à révéler votre potentiel et à vivre en harmonie avec vous-même et le monde."
                  : "Я верю, что истинная трансформация начинается изнутри. Моя работа основана на глубоком понимании человеческой психики и использовании духовных практик, которые позволяют вам обрести гармонию и внутреннюю целостность. Вместе мы создадим пространство, где наука и древние духовные знания работают в едином потоке, помогая вам раскрыть свой потенциал и жить в гармонии с собой и миром."}
              </p>
              </div>
            </div>
          </section>

          <section id="mission" className="mission">
            <div className="grid-container">
              <h2 className='grid-item-span-12'>{language === 'fr' ? 'Mission' : 'Миссия'}</h2>
              <div className="grid-item grid-item-span-4">
                <img src={Mission} alt="Mision" />
                <h3>{language === 'fr'
                    ? "Mission"
                    : "Миссия"}</h3>
                <p className='small'>
                  {language === 'fr'
                    ? "Accompagner les gens dans leur processus de transformation intérieure et de découverte de soi, en leur ouvrant la voie vers une compréhension profonde d'eux-mêmes et de leur place dans le monde."
                    : "Проводить людей через процесс внутреннего преображения и самопознания, открывая им путь к глубокому пониманию себя и своего места в мире."}
                </p>
              </div>
              <div className="grid-item grid-item-span-4">
                <img src={Objectif} alt="Objectif" />
                <h3>{language === 'fr'
                    ? "Objectif"
                    : "Цель"}</h3>
                <p className='small'>
                  {language === 'fr'
                    ? "Inspirer les personnes à trouver l'harmonie et le sens de leur vie, en les guidant à travers le processus de découverte de soi et de croissance personnelle. Je les aide à atteindre une satisfaction intérieure, la joie et la plénitude, en unifiant l'esprit, le corps et l'âme en un tout harmonieux."
                    : "Вдохновлять людей на обретение гармонии и смысла в жизни, проводя их через процесс самопознания и личностного роста. Я помогаю своим клиентам находить внутреннюю удовлетворенность, радость и целостность, соединяя ум, тело и душу в единое, сбалансированное целое."}
                </p>
              </div>
              <div className="grid-item grid-item-span-4">
                <img src={Unicite} alt="Unicité" />
                <h3>{language === 'fr'
                    ? "Unicité"
                    : "Уникальность"}</h3>
                    <ul>
                      <li className='small'>
                      {language === 'fr'
                        ? "Intégration des connaissances spirituelles anciennes avec la psychologie clinique, fusionnant ainsi spiritualité et science en une unité cohérente."
                        : "Интеграция древних духовных знаний с клинической психологией, соединяя духовность и науку в единое целое. "}
                    </li>
                    <li className='small'>
                      {language === 'fr'
                        ? "Perception holistique de la personne dans toutes ses dimensions : physique, psychologique et spirituelle."
                        : "Целостное восприятие человека во всех его измерениях: физическом, психологическом и духовном."}
                    </li>
                    </ul>
                
                <button className='link-button' onClick={toggleUnicity} >
                  {language === 'fr'
                    ? "Lire la suite"
                    : "Читать далее"}
                </button>
              </div>  
              <div className="grid-item grid-item-span-12">
                <h3>{language === 'fr'
                    ? "Mon rôle en tant que Conseillère Psycho-Spirituelle"
                    : "Моя роль как ПсихоДуховного Советника"}</h3>
                <p>
                  {language === 'fr'
                    ? "En tant que spécialiste, je ne suis pas seulement consultante, mais aussi conseillère personnelle et mentor. Je suis ici pour vous aider à trouver l'équilibre et la plénitude, pour vous accompagner sur votre chemin, vous soutenir et vous guider dans les moments difficiles, et ensemble, ouvrir de nouveaux horizons dans votre vie."
                    : "Как специалист, я выступаю не только в роли консультанта, но и в роли личного советника и наставника. Я здесь, чтобы помочь вам найти баланс и целостность, сопровождать вас на вашем пути, поддерживать и направлять в трудные моменты, вместе с вами открывать новые горизонты вашей жизни."}
                </p>
                <p><strong>
                  {language === 'fr'
                    ? "Avec moi, vous pouvez:"
                    : "Со мной вы можете:"}
                </strong></p>
                <ul>
                  <li>
                    {language === 'fr'
                      ? "Parler de tout;"
                      : "Говорить обо всем;"}
                  </li>
                  <li>
                    {language === 'fr'
                      ? "Être en colère;"
                      : "Злиться;"}
                  </li>
                  <li>
                    {language === 'fr'
                      ? "Crier;"
                      : "Кричать;"}
                  </li>
                  <li>
                    {language === 'fr'
                      ? "Pleurer;"
                      : "Плакать;"}
                  </li>
                  <li>
                    {language === 'fr'
                      ? "Rire;"
                      : "Смеяться;"}
                  </li>
                  <li>
                    {language === 'fr'
                      ? "Explorer vos rêves;"
                      : "Исследовать мечты;"}
                  </li>
                  <li>
                    {language === 'fr'
                      ? "Être sûr d'être compris et non jugé."
                      : "Быть уверенными, что вас поймут и не осудят."}
                  </li>
                </ul>
                  <p><strong>
                    {language === 'fr'
                      ? "Règle d'or: CONFIDENTIALITÉ"
                      : "Главное правило: КОНФИДЕНЦИАЛЬНОСТЬ."}
                  </strong></p>
                 <p>
                    {language === 'fr'
                      ? "Dans un monde plein de bruit et d'agitation, je vous offre un espace de calme et de compréhension. Ici, vous pouvez être vous-même, explorer en toute sécurité vos mondes intérieurs et trouver des réponses aux questions les plus importantes."
                      : "В мире, полном шума и суеты, я предлагаю вам место спокойствия и понимания. Здесь вы можете быть собой, безопасно исследовать свои внутренние миры и находить ответы на самые важные вопросы."}
                  </p>
                  <p><strong>
                    {language === 'fr'
                      ? "ICI COMMENCE VOTRE VOYAGE VERS L'HARMONIE INTÉRIEURE ET LA PLEINE CONSCIENCE."
                      : "ЗДЕСЬ НАЧИНАЕТСЯ ВАШЕ ПУТЕШЕСТВИЕ К ВНУТРЕННЕЙ ГАРМОНИИ И ОСОЗНАННОСТИ."}
                  </strong></p>
              </div>  
            </div>
          </section>

          <section id="expertise" className="expertise">
            <div className="grid-container">
              <h2 className='grid-item-span-12'>{language === 'fr' ? 'Comment puis-je vous être utile ?' : 'чем я могу быт полезна?'}</h2>
              <div className="grid-item grid-item-span-8 texte">
                <p><strong><em>
                  {language === 'fr'
                    ? "En tant que conseillère psycho-spirituelle, je peux vous aider à:"
                    : "Как психодуховный советник, я могу помочь вам:"}
                </em></strong></p>
                <ul>
                  <li>
                    <strong>
                      {language === 'fr'
                        ? "Trouver l'harmonie intérieure:"
                        : "Найти внутреннюю гармонию:"}
                    </strong>
                    <span style={{marginLeft: '0.3rem'}}>
                      {language === 'fr'
                        ? "Je vous aide à unifier l'esprit, le corps et l'âme en un tout cohérent, afin que vous puissiez vivre dans l'équilibre et la sérénité, indépendamment des circonstances extérieures."
                        : "Я помогаю вам соединить ум, тело и душу в единое целое, чтобы вы могли жить в балансе и спокойствии, независимо от внешних обстоятельств."}
                    </span>
                  </li>
                  <li>
                    <strong>
                      {language === 'fr'
                        ? "Surmonter les conflits intérieurs:"
                        : "Преодолеть внутренние конфликты: "}
                    </strong>
                    <span style={{marginLeft: '0.3rem'}}>
                      {language === 'fr'
                        ? "Ensemble, nous explorerons vos contradictions internes et trouverons des moyens de les résoudre, pour que vous puissiez avancer, libéré du poids du passé."
                        : "Вместе мы исследуем ваши внутренние противоречия и найдём пути к их разрешению, чтобы вы могли двигаться вперёд, освободившись от груза прошлого."}
                    </span>
                  </li>
                  <li>
                    <strong>
                      {language === 'fr'
                        ? "Révéler votre potentiel:"
                        : "Раскрыть свой потенциал:"}
                    </strong>
                    <span style={{marginLeft: '0.3rem'}}>
                      {language === 'fr'
                        ? "Grâce aux connaissances spirituelles anciennes et aux méthodes scientifiques de la psychologie, je vous aide à découvrir et à développer vos ressources cachées, ouvrant ainsi de nouveaux horizons pour votre croissance personnelle."
                        : "С помощью древних духовных знаний и научных методов психологии, я помогаю вам обнаружить и развить свои скрытые ресурсы, открывая новые горизонты для личностного роста."}
                    </span>
                  </li>
                  <li>
                    <strong>
                      {language === 'fr'
                        ? "Faire face aux crises et au stress:"
                        : "Справиться с кризисами и стрессами:"}
                    </strong>
                    <span style={{marginLeft: '0.3rem'}}>
                      {language === 'fr'
                        ? "Je vous soutiendrai dans les moments difficiles, vous aidant à trouver un sens et un point d'appui, pour que vous puissiez surmonter toutes les difficultés de la vie."
                        : "Я поддержу вас в трудные моменты, помогу найти смысл и опору, чтобы вы могли преодолеть любые жизненные трудности."}
                    </span>
                  </li>
                  <li>
                    <strong>
                      {language === 'fr'
                        ? "Acquérir confiance en vous et en votre chemin:"
                        : "Обрести уверенность в себе и своём пути:"}
                    </strong>
                    <span style={{marginLeft: '0.3rem'}}>
                      {language === 'fr'
                        ? "Je vous aiderai à mieux vous comprendre, à clarifier vos désirs et vos objectifs, ce qui vous permettra de progresser dans la vie avec confiance et joie."
                        : "Я помогу вам лучше понять себя, свои желания и цели, что позволит вам с уверенностью и радостью двигаться по жизни."}
                    </span>
                  </li>
                  <li>
                    <strong>
                      {language === 'fr'
                        ? "Renforcer vos fondations spirituelles et psychologiques:"
                        : "Укрепить ваши духовные и психологические основы:"}
                    </strong>
                    <span style={{marginLeft: '0.3rem'}}>
                      {language === 'fr'
                        ? "Ensemble, nous construirons des bases solides qui vous soutiendront dans toutes les situations de la vie."
                        : "Вместе мы создадим прочные внутренние опоры, которые будут поддерживать вас в любых жизненных ситуациях."}
                    </span>
                  </li>
                  <li>
                    <strong>
                      {language === 'fr'
                        ? "Vous adapter aux changements et aux nouveaux défis:"
                        : "Адаптироваться к изменениям и новым вызовам:"}
                    </strong>
                    <span style={{marginLeft: '0.3rem'}}>
                      {language === 'fr'
                        ? "Je vous aiderai à accepter et à vous adapter aux changements, à les voir comme des opportunités de croissance et de développement."
                        : "Я помогу вам принять и адаптироваться к изменениям, научиться видеть в них возможности для роста и развития."}
                    </span>
                  </li>
                  <li>
                    <strong>
                      {language === 'fr'
                        ? "Explorer et réaliser vos rêves:"
                        : "Исследовать и реализовать свои мечты:"}
                    </strong>
                    <span style={{marginLeft: '0.3rem'}}>
                      {language === 'fr'
                        ? "Avec moi, vous pourrez parler ouvertement de vos rêves, les explorer et trouver des moyens de les réaliser, en sachant que vous serez compris et soutenu."
                        : "Со мной вы сможете открыто говорить о своих мечтах, исследовать их и находить пути к их реализации, зная, что вас поймут и поддержат."}
                    </span>
                  </li>
                </ul>
                <h4>
                  {language === 'fr'
                    ? "ENSEMBLE, NOUS CRÉERONS UN ESPACE OÙ VOUS POURREZ VOUS EXPLORER EN TOUTE SÉCURITÉ ET EN PROFONDEUR, TROUVER DES RÉPONSES AUX QUESTIONS IMPORTANTES ET AVANCER VERS UNE VIE REMPLIE D'HARMONIE, DE JOIE ET DE SENS."
                    : "ВМЕСТЕ МЫ СОЗДАДИМ ПРОСТРАНСТВО, ГДЕ ВЫ СМОЖЕТЕ БЕЗОПАСНО И ГЛУБОКО ИССЛЕДОВАТЬ СЕБЯ, НАХОДИТЬ ОТВЕТЫ НА ВАЖНЫЕ ВОПРОСЫ И ДВИГАТЬСЯ К ЖИЗНИ, НАПОЛНЕННОЙ ГАРМОНИЕЙ, РАДОСТЬЮ И СМЫСЛОМ."}
                </h4>
              </div>
              <div className="grid-item grid-item-span-4 image"><img src={Expertise} alt="Expertise" /></div>
            </div>
          </section>

          <section id="services" className="services">
            <div className="grid-container">
              <h2 className='grid-item-span-12'>{language === 'fr' ? 'Services et Tarifs' : 'Услуги и Стоимость'}</h2>
              <div className="grid-item grid-item-span-4">
                <img src={Consultation} alt="Consultation" />
                <div className='card-content'>
                  <h3 className='card-title'>
                    {language === 'fr'
                      ? "Consultation individuelle "
                      : "Консультация индивидуальная "}
                  </h3>
                  <p className='price'>
                    {language === 'fr'
                      ? "(1h30) – 80 € TVAC "
                      : "(1 час 30 минут) – 80 € НДС"}
                  </p>
                  <p className='small'>
                    {language === 'fr'
                      ? "La consultation se déroule exclusivement en ligne et permet d'explorer en profondeur vos demandes actuelles, vos besoins et vos états intérieurs. Pendant une heure et demie, nous nous concentrerons sur la compréhension de vos expériences, la recherche de sources de force et d'inspiration ..."
                      : "Консультация проходит исключительно онлайн и позволяет глубоко исследовать ваши текущие запросы, потребности и внутренние состояния. В течение полутора часов мы сосредоточимся на том, чтобы понять ваши переживания, найти источники силы и вдохновения, а также разработать шаги для достижения ..."}
                  </p>
                  <button className='link-button' onClick={toggleConsultation}>
                    {language === 'fr'
                      ? "Lire la suite"
                      : "Читать далее"}
                  </button>
                </div>
              </div>
              <div className="grid-item grid-item-span-4">
                <img src={Ceremonie} alt="Cérémonie" />
                <div className='card-content'>
                  <h3 className='card-title'>
                    {language === 'fr'
                      ? "Cérémonie de pelennage"
                      : "Церемония пеленания"}
                  </h3>
                  <p className='card-price'>
                    {language === 'fr'
                      ? "(5-6 heures) – 700 € TVAC"
                      : "(5-6 часов) – 700 € НДС"}
                  </p>
                  <p className='small'>
                    {language === 'fr'
                      ? "La cérémonie de pelennage est un rituel de transformation unique, qui se déroule en plusieurs étapes. Nous commençons par définir votre demande et par une consultation afin de mieux comprendre votre situation et vos intentions. Ensuite, suivent des séances d'art-thérapie et de pratiques ..."
                      : "Церемония пеленания — это уникальный трансформационный ритуал, который проводится в несколько этапов. Мы начинаем с определения вашего запроса и консультации, чтобы глубже понять вашу ситуацию и намерения. Далее следуют арт-терапия и телесные практики, которые помогают вам выразить и освободить ... "}
                  </p>
                  <button className='link-button' onClick={toggleCeremonie}>
                    {language === 'fr'
                      ? "Lire la suite"
                      : "Читать далее"}
                  </button>
                </div>
              </div>
              <div className="grid-item grid-item-span-4">
                <img src={Accompagnement} alt="Accompagnement" />
                <div className='card-content'>
                  <h3 className='card-title'>
                    {language === 'fr'
                      ? "Accompagnement personnalisé"
                      : "Личное ведение"}
                  </h3>
                  <p className='card-price'>
                    {language === 'fr'
                      ? "1500 € TVAC par mois"
                      : "1500 € НДС"}
                  </p>
                  <p className='small'>
                    {language === 'fr'
                      ? "Ce format prévoit un travail intensif et personnalisé pendant un mois. Je vous accompagnerai à chaque étape de votre parcours, en vous soutenant, en vous inspirant et en vous aidant à surmonter toutes les difficultés. L'accompagnement personnalisé est idéal pour ceux qui  ..."
                      : "Этот формат предусматривает интенсивную и персонализированную работу в течение месяца. Я буду сопровождать вас на всех этапах вашего пути, оказывая поддержку, вдохновляя и помогая преодолевать любые трудности. Личное ведение идеально подходит для тех, кто готов  ... "}
                  </p>
                  <button className='link-button' onClick={toggleAccompagnement}>
                    {language === 'fr'
                      ? "Lire la suite"
                      : "Читать далее"}
                  </button>
                </div>
              </div>  
              <div className='grid-item grid-item-span-12' style={{textAlign:"center"}}>
              <p><strong>{language === 'fr' ? "LES SERVICES D'INTERPRÈTE SONT À LA CHARGE DU CLIENT SÉPARÉMENT." : "УСЛУГИ ПЕРЕВОДЧИКА ОПЛАЧИВАЮТСЯ ОТДЕЛЬНО И ПРЕДОСТАВЛЯЮТСЯ ПО ЖЕЛАНИЮ И НЕОБХОДИМОСТИ КЛИЕНТА."}</strong></p>
              </div>
            </div>
          </section>
        </content>
        {isScrolled && (
  <button className="back-to-top" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
    ↑
  </button>
)}
      </main>

      <footer>
        <div id="contact" className='footer-top'>
          <div className='bottom-menu'>
            <button href="#" onClick={toggleTerms} className='link-button'>{language === 'fr' ? 'Informations légales ' : 'юридическая информация'}</button>
            <Email email="info@smirnova.consulting">info@smirnova.consulting</Email>
          </div>

          

          <div className="bottom-menu-social">
            <a href="http://t.me/tribute/app?startapp=se7q" target="_blank" rel="noopener noreferrer">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_116)">
                  <path d="M20.0001 0C8.9601 0 0.00012207 8.95998 0.00012207 20C0.00012207 31.0399 8.9601 39.9999 20.0001 39.9999C31.0401 39.9999 40 31.0399 40 20C40 8.95998 31.0401 0 20.0001 0ZM29.2801 13.6C28.9801 16.76 27.6801 24.44 27.0201 27.9799C26.7401 29.4799 26.1801 29.9799 25.6601 30.0399C24.5001 30.1399 23.6201 29.2799 22.5001 28.5399C20.7401 27.3799 19.7401 26.6599 18.0401 25.5399C16.0601 24.24 17.3401 23.52 18.4801 22.36C18.7801 22.06 23.9001 17.4 24.0001 16.98C24.014 16.9164 24.0121 16.8503 23.9947 16.7876C23.9773 16.7248 23.9448 16.6673 23.9001 16.62C23.7801 16.52 23.6201 16.56 23.4801 16.58C23.3001 16.62 20.5001 18.48 15.0401 22.16C14.2401 22.7 13.5201 22.98 12.8801 22.96C12.1601 22.94 10.8001 22.56 9.7801 22.22C8.5201 21.82 7.54011 21.6 7.62011 20.9C7.66011 20.54 8.16011 20.18 9.1001 19.8C14.9401 17.26 18.8201 15.58 20.7601 14.78C26.3201 12.46 27.4601 12.06 28.2201 12.06C28.3801 12.06 28.7601 12.1 29.0001 12.3C29.2001 12.46 29.2601 12.68 29.2801 12.84C29.2601 12.96 29.3001 13.32 29.2801 13.6Z"/>
                </g>
                <defs>
                  <clipPath id="clip0_1_116">
                    <rect width="40" height="40" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a href="https://www.instagram.com/smi_yulik?igsh=MXQwOHZvZTk2Y3ppZQ==" target="_blank" rel="noopener noreferrer">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_114)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 17.7778C0 9.39778 -2.38419e-07 5.20667 2.60444 2.60444C5.20889 0.00222206 9.39778 0 17.7778 0H22.2222C30.6022 0 34.7933 -2.38419e-07 37.3956 2.60444C39.9978 5.20889 40 9.39778 40 17.7778V22.2222C40 30.6022 40 34.7933 37.3956 37.3956C34.7911 39.9978 30.6022 40 22.2222 40H17.7778C9.39778 40 5.20667 40 2.60444 37.3956C0.00222206 34.7911 0 30.6022 0 22.2222V17.7778ZM33.3333 10C33.3333 10.8841 32.9821 11.7319 32.357 12.357C31.7319 12.9821 30.8841 13.3333 30 13.3333C29.1159 13.3333 28.2681 12.9821 27.643 12.357C27.0179 11.7319 26.6667 10.8841 26.6667 10C26.6667 9.11594 27.0179 8.2681 27.643 7.64298C28.2681 7.01786 29.1159 6.66667 30 6.66667C30.8841 6.66667 31.7319 7.01786 32.357 7.64298C32.9821 8.2681 33.3333 9.11594 33.3333 10ZM24.4444 22.2222C24.4444 23.401 23.9762 24.5314 23.1427 25.3649C22.3092 26.1984 21.1787 26.6667 20 26.6667C18.8213 26.6667 17.6908 26.1984 16.8573 25.3649C16.0238 24.5314 15.5556 23.401 15.5556 22.2222C15.5556 21.0435 16.0238 19.913 16.8573 19.0795C17.6908 18.246 18.8213 17.7778 20 17.7778C21.1787 17.7778 22.3092 18.246 23.1427 19.0795C23.9762 19.913 24.4444 21.0435 24.4444 22.2222ZM28.8889 22.2222C28.8889 24.5797 27.9524 26.8406 26.2854 28.5076C24.6184 30.1746 22.3575 31.1111 20 31.1111C17.6425 31.1111 15.3816 30.1746 13.7146 28.5076C12.0476 26.8406 11.1111 24.5797 11.1111 22.2222C11.1111 19.8647 12.0476 17.6038 13.7146 15.9368C15.3816 14.2698 17.6425 13.3333 20 13.3333C22.3575 13.3333 24.6184 14.2698 26.2854 15.9368C27.9524 17.6038 28.8889 19.8647 28.8889 22.2222Z"/>
                </g>
                <defs>
                  <clipPath id="clip0_1_114">
                    <rect width="40" height="40" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a href="https://www.facebook.com/juliya.onyschenko" target="_blank" rel="noopener noreferrer">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_112)">
                  <path d="M20 6.10352e-05C8.99999 6.10352e-05 0 9.01612 0 20.1205C0 30.1607 7.31999 38.494 16.88 40V25.9438H11.8V20.1205H16.88V15.6828C16.88 10.6426 19.86 7.87154 24.44 7.87154C26.62 7.87154 28.9 8.25306 28.9 8.25306V13.2129H26.38C23.9 13.2129 23.12 14.7591 23.12 16.3454V20.1205H28.68L27.78 25.9438H23.12V40C27.8329 39.2527 32.1244 36.8384 35.2198 33.1929C38.3153 29.5474 40.0106 24.911 39.9999 20.1205C39.9999 9.01612 31 6.10352e-05 20 6.10352e-05Z"/>
                </g>
                <defs>
                  <clipPath id="clip0_1_112">
                    <rect width="40" height="40" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
        
        <div className='footer-bottom'>
          <p className='small'>{currentYear} © <a href="/" className='link-button'>Smirnova Consulting</a> Tous droits réservés</p>
          <div className="bottom-menu-languages">
            <button onClick={() => switchLanguage('fr')} className="lang-button">🇫🇷</button>
            <button onClick={() => switchLanguage('ru')} className="lang-button">🇷🇺</button>
          </div>
        </div>
      </footer>

      <CSSTransition in={termsOpen} timeout={300} classNames="terms" unmountOnExit>
        <div className="terms-overlay" style={{ zIndex: 40 }} onClick={toggleTerms}>
          <div className="terms-popup" onClick={(e) => e.stopPropagation()}>
            <button className="close-terms" onClick={toggleTerms}>X</button>
            <h2>{language === 'fr' ? 'Informations légales' : 'Юридическая информация'}</h2>
            <p>
              {language === 'fr'
                ? "Bienvenue sur le site web Smirnova Consulting. Smirnova Consulting est une filiale de Productions Associées dont le siège social est à Ixelles (1060) Rue Emile Féron 70, immatriculée au registre du commerce et des sociétés de Bruxelles sous le numéro de TVA BE 0896.755.397."
                : "Добро пожаловать на сайт Смирнова Консалтинг. Smirnova Consulting является дочерней компанией Productions Associés, головной офис которой находится по адресу: Иксель (1060), улица Эмиль Ферон, 70, зарегистрированной в Брюссельском реестре предприятий и компаний под номером НДС BE 0896.755.397."}
            </p>
            <h3>{language === 'fr' ? 'Utilisation du site web' : 'Использование веб-сайта'}</h3>
            <p>
              {language === 'fr'
                ? "Les informations présentées sur ce site sont exclusivement destinées à une information générale. Les sections de ce site s’adressent à certains groupes cibles spécifiques comme des prospects et des clients. Votre accès aux informations proposées sur ce site et l’usage que vous en faites sont soumis aux présentes Conditions d’utilisation. En accédant à ce site et en l’utilisant, vous acceptez les présentes conditions d’utilisation sans restriction ni réserve."
                : "Информация, представленная на этом сайте, предназначена исключительно для общего ознакомления. Разделы этого сайта предназначены для определенных целевых групп, таких как потенциальные клиенты и клиенты. Ваш доступ к информации, предлагаемой на этом сайте, и ее использование регулируются настоящими Условиями использования. Заходя на этот сайт и используя его, вы принимаете эти условия использования без ограничений и оговорок."
              }
            </p>

            <h3>{language === 'fr' ? 'Contenu' : 'Содержание'}</h3>
            <p>
              {language === 'fr'
                ? "Smirnova Consulting consentira des efforts raisonnables afin de reprendre sur ce site web des informations précises et actuelles. Mais nous n’offrons aucune garantie ni engagement concernant la précision, la validité, la pertinence ou l’exhaustivité des informations fournies. Vous acceptez que l’utilisation du site web et de son contenu soit sous votre propre responsabilité."
                : "Смирнова Консалтинг приложит разумные усилия для предоставления точной и актуальной информации на этом сайте. Но мы не даем никаких гарантий или обязательств относительно точности, достоверности, актуальности или полноты предоставленной информации. Вы соглашаетесь с тем, что используете веб-сайт и его содержимое на свой страх и риск."
              }
            </p>

            <h3>{language === 'fr' ? 'Dommages & intérêts' : 'Ущерб и интересы'}</h3>
            <p>
              {language === 'fr'
                ? "Vous acceptez de prémunir, de défendre et de disculper Smirnova Consulting, les membres de son conseil d’administration, ses directeurs, ses employés, ses représentants et ses fournisseurs, ainsi que tous les partenaires tiers possibles contre toutes les pertes, toutes les dépenses, tous les sinistres et tous les frais possibles, y compris les honoraires d’avocat découlant de toute infraction de votre part aux présentes conditions d’utilisation."
                : "Вы соглашаетесь возмещать, защищать и ограждать Smirnova Consulting, ее членов совета директоров, директоров, сотрудников, представителей и поставщиков, а также всех возможных сторонних партнеров от всех убытков, расходов, всех возможных претензий и издержек, включая гонорары адвокатов, возникающих от любого нарушения вами настоящих Условий использования."
              }
            </p>

            <h3>{language === 'fr' ? 'Vie privée' : 'Конфиденциальность'}</h3>
            <p>
              {language === 'fr'
                ? "Smirnova Consulting respecte la vie privée de tous ceux qui visitent son site web. Veuillez lire la notice de Smirnova Consulting en matière de respect de la vie privée pour tout savoir sur vos droits et vos responsabilités à l’égard des informations mises à votre disposition sur ce site."
                : "Smirnova Consulting уважает конфиденциальность каждого, кто посещает ее сайт. Пожалуйста, прочтите уведомление о конфиденциальности Smirnova Consulting, чтобы узнать о ваших правах и обязанностях в отношении информации, доступной вам на этом сайте."
              }
            </p>

            <h3>{language === 'fr' ? 'Sites web externes et liens vers des tiers' : 'Внешние веб-сайты и сторонние ссылки'}</h3>
            <p>
              {language === 'fr'
                ? "Ce site web peut contenir des liens ou des références vers d’autres sites web. Ces sites sont gérés par des tiers sur lesquels Smirnova Consulting n’a aucun contrôle. Ces liens vous sont présentés exclusivement à titre de service."
                : "Этот веб-сайт может содержать ссылки или ссылки на другие веб-сайты. Эти сайты управляются третьими лицами, которых Smirnova Consulting не контролирует. Эти ссылки предоставляются вам исключительно в качестве услуги."
              }
            </p>

            <h3>{language === 'fr' ? 'Cookies' : 'Файлы cookie'}</h3>
            <p>
              {language === 'fr'
                ? "Le site web de Smirnova Consulting utilise des cookies pour faciliter et rendre plus agréable votre expérience en ligne, et pour mieux aligner le contenu de ses sites web en fonction de vos besoins et vos préférences."
                : "Веб-сайт Smirnova Consulting использует файлы cookie, чтобы сделать вашу работу в Интернете проще и приятнее, а также лучше согласовать содержимое своих веб-сайтов с вашими потребностями и предпочтениями."
              }
            </p>

            <h3>{language === 'fr' ? 'Droits d’auteur' : 'Авторское право'}</h3>
            <p>
              {language === 'fr'
                ? "La totalité du contenu du site web est protégée par la législation sur les droits d’auteur. Le contenu de ce site web ne peut être copié, sauf pour une consultation individuelle non commerciale."
                : "Все содержимое сайта защищено законом об авторском праве. Содержимое этого веб-сайта запрещено копировать, за исключением случаев индивидуального некоммерческого просмотра."
              }
            </p>

            <h3>{language === 'fr' ? 'Lois qui régissent cet accord' : 'Законы, регулирующие настоящее соглашение'}</h3>
            <p>
              {language === 'fr'
                ? "Les présentes Conditions d’utilisation et votre accès au site web sont régis par le droit Belge. Toute action en relation avec ce site sera tranchée par un tribunal Belge compétent."
                : "Настоящие Условия использования и ваш доступ к веб-сайту регулируются законодательством Бельгии. Любое действие в отношении этого сайта будет приниматься исключительно компетентным бельгийским судом."
              }
            </p>

            <h3>{language === 'fr' ? 'Divers' : 'Разнообразный'}</h3>
            <p>
              {language === 'fr'
                ? "Si une quelconque clause des présentes Conditions d’utilisation est déclarée illégale, nulle ou non-exécutoire, cette clause sera réputée non-écrite sans préjudice de l’applicabilité de toutes les clauses restantes."
                : "Если какое-либо положение настоящих Условий использования будет объявлено незаконным, недействительным или не имеющим исковой силы, это положение будет считаться неписаным без ущерба для применимости всех остальных положений."
              }
            </p>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition in={unicityOpen} timeout={300} classNames="terms" unmountOnExit>
        <div className="terms-overlay" style={{ zIndex: 40 }} onClick={toggleUnicity}>
          <div className="terms-popup" onClick={(e) => e.stopPropagation()}>
            <button className="close-terms" onClick={toggleUnicity}>X</button>
            <h2>{language === 'fr' ? 'Unicité' : 'Уникальность'}</h2>
            
            <ul>
              <li>
                {language === 'fr'
                  ? "Intégration des connaissances spirituelles anciennes avec la psychologie clinique, fusionnant ainsi spiritualité et science en une unité cohérente."
                  : "Интеграция древних духовных знаний с клинической психологией, соединяя духовность и науку в единое целое."}
              </li>
              <li>{language === 'fr' ? "Perception holistique de la personne dans toutes ses dimensions : physique, psychologique et spirituelle." : "Целостное восприятие человека во всех его измерениях: физическом, психологическом и духовном."}</li>
              <li>{language === 'fr' ? "Capacité à s'adapter aux besoins des clients issus de différents contextes culturels, nationaux et religieux." : "Способность адаптироваться к нуждам клиентов из разных культурных, национальных и религиозных контекстов."}</li>
              <li>{language === 'fr' ? "Plan de travail individualisé, élaboré spécialement pour chaque client." : "Индивидуальный план работы, разработанный специально для каждого клиента."}</li>
            </ul>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition in={consultationOpen} timeout={300} classNames="terms" unmountOnExit>
        <div className="terms-overlay" style={{ zIndex: 40 }} onClick={toggleConsultation}>
          <div className="terms-popup" onClick={(e) => e.stopPropagation()}>
            <button className="close-terms" onClick={toggleConsultation}>X</button>
            <h2>{language === 'fr' ? 'Consultation individuelle' : 'Консультация индивидуальная'}</h2>
            <p>
              {language === 'fr'
                ? "La consultation se déroule exclusivement en ligne et permet d'explorer en profondeur vos demandes actuelles, vos besoins et vos états intérieurs. Pendant une heure et demie, nous nous concentrerons sur la compréhension de vos expériences, la recherche de sources de force et d'inspiration, ainsi que sur l'élaboration de stratégies pour atteindre vos objectifs. Le format en ligne offre flexibilité et confort, vous permettant de travailler sur vous-même dans un environnement apaisant."
                : "Консультация проходит исключительно онлайн и позволяет глубоко исследовать ваши текущие запросы, потребности и внутренние состояния. В течение полутора часов мы сосредоточимся на том, чтобы понять ваши переживания, найти источники силы и вдохновения, а также разработать шаги для достижения ваших целей. Онлайн-формат обеспечивает гибкость и удобство, позволяя вам работать над собой в комфортных условиях."
              }
            </p>
            <p>{language === 'fr' ? '(1h30) – 80 € TVAC' : '(1 час 30 минут) – 80 € НДС'}</p>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition in={ceremonieOpen} timeout={300} classNames="terms" unmountOnExit>
        <div className="terms-overlay" style={{ zIndex: 40 }} onClick={toggleCeremonie}>
          <div className="terms-popup" onClick={(e) => e.stopPropagation()}>
            <button className="close-terms" onClick={toggleCeremonie}>X</button>
            <h2>{language === 'fr' ? 'Cérémonie de pelennage' : 'Церемония пеленания'}</h2>
            <p>
              {language === 'fr'
                ? "La cérémonie de pelennage est un rituel de transformation unique, qui se déroule en plusieurs étapes. Nous commençons par définir votre demande et par une consultation afin de mieux comprendre votre situation et vos intentions. Ensuite, suivent des séances d'art-thérapie et de pratiques corporelles qui vous aident à exprimer et à libérer les émotions et les tensions accumulées. Le point culminant de la cérémonie est le travail sur le plan énergétique, où nous clôturons une étape importante de votre vie, récupérons l'énergie qui en émane et la redirigeons vers l'ouverture de nouvelles opportunités et horizons."
                : "Церемония пеленания — это уникальный трансформационный ритуал, который проводится в несколько этапов. Мы начинаем с определения вашего запроса и консультации, чтобы глубже понять вашу ситуацию и намерения. Далее следуют арт-терапия и телесные практики, которые помогают вам выразить и освободить накопленные эмоции и напряжения. Кульминацией церемонии становится работа на энергетическом плане, где мы завершаем важный жизненный этап, забираем из него энергию и направляем её на открытие новых возможностей и горизонтов."
              }
            </p>
            <p>
              {language === 'fr'
                ? "Cette cérémonie est idéale pour des moments significatifs tels que le mariage, un déménagement, un anniversaire, une grossesse, la fermeture de la période postnatale, un changement de travail, une promotion, la perte d'un être cher, des pertes périnatales, un divorce ou d'autres transitions importantes dans la vie. Le pelennage aide non seulement à laisser le passé derrière soi, mais aussi à accueillir le nouveau avec un cœur ouvert."
                : "Церемония идеально подходит для таких значимых моментов, как свадьба, переезд, день рождения, беременность, закрытие родов, смена работы, переход на новую должность, потеря близкого, перинатальные потери, развод или другие важные переходы в жизни. Пеленание помогает не только отпустить прошлое, но и с открытым сердцем принять новое."
              }
            </p>
            <p>{language === 'fr' ? '(5-6 heures) – 700 € TVAC' : '(5-6 часов) – 700 € НДС'}</p>
            <p>{language === 'fr' ? "LES SERVICES DE PHOTOGRAPHE ET D'INTERPRÈTE SONT EN SUPPLÉMENT ET SONT PROPOSÉS EN FONCTION DES BESOINS ET DES SOUHAITS DU CLIENT." : "УСЛУГИ ФОТОГРАФА И ПЕРЕВОДЧИКА ОПЛАЧИВАЮТСЯ ОТДЕЛЬНО И ПРЕДОСТАВЛЯЮТСЯ ПО ЖЕЛАНИЮ И НЕОБХОДИМОСТИ КЛИЕНТА."}</p>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition in={accompagnementOpen} timeout={300} classNames="terms" unmountOnExit>
        <div className="terms-overlay" style={{ zIndex: 40 }} onClick={toggleAccompagnement}>
          <div className="terms-popup" onClick={(e) => e.stopPropagation()}>
            <button className="close-terms" onClick={toggleAccompagnement}>X</button>
            <h2>{language === 'fr' ? 'Accompagnement personnalisé' : 'Личное ведение'}</h2>
            <p>
              {language === 'fr'
                ? "Ce format prévoit un travail intensif et personnalisé pendant un mois. Je vous accompagnerai à chaque étape de votre parcours, en vous soutenant, en vous inspirant et en vous aidant à surmonter toutes les difficultés. L'accompagnement personnalisé est idéal pour ceux qui sont prêts à des changements profonds et qui visent des résultats durables. C'est l'occasion de travailler sur vous-même avec une pleine implication et une attention à chaque détail de votre monde intérieur et extérieur."
                : "Этот формат предусматривает интенсивную и персонализированную работу в течение месяца. Я буду сопровождать вас на всех этапах вашего пути, оказывая поддержку, вдохновляя и помогая преодолевать любые трудности. Личное ведение идеально подходит для тех, кто готов к глубоким изменениям и стремится к долгосрочным результатам. Это возможность работать над собой с полной отдачей и вниманием к каждой детали вашего внутреннего и внешнего мира."
              }
            </p>
            <p>{language === 'fr' ? '1500 € TVAC par mois' : '1500 € НДС за месяц'}</p>
            
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default App;